import React from "react";
import "../App.css";
import myphoto from "../media/MAGGIE_HS_IU-1.jpg";

export function Mobile() {
  return (
    <section id="about">
      <div className="about">
        <h2 className="sectionheader">About Me</h2>
        <img src={myphoto} alt="Maggie Chew Headshot" />
        <div>
          <div className="about-text">
            <span>
              <b>Hi, my name is Maggie, and I'm a Full-Stack Developer.</b>
            </span>
            <p>
              I love to solve complex problems, to constantly learn and
              challenge myself, and to make great things happen with great
              people. <br />
              <br />
              Hailing originally from Calgary, I first moved to Toronto, then
              Boston, to pursue Bachelor's and Master's Degrees in Music
              Performance. After sustaining an injury to my wrist, I then
              pivoted to arts management, and then the non-profit world at
              large, where I worked for the last decade. In 2022, I decided it
              was time to make a change, and took a chance on a program at
              Inception U, becoming a Full-Stack Developer.
              <br /> <br />
              I'm so thrilled to be in my dream career, and can't wait to join
              your team!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export function AboutMe() {
  return (
    <section id="about">
      <div className="about">
        <img src={myphoto} alt="Maggie Chew Headshot" />
        <div>
          <h2 className="sectionheader">About Me</h2>
          <div className="about-text">
            <span>
              <b>Hi, my name is Maggie, and I'm a Full-Stack Developer.</b>
            </span>
            <p>
              I love to solve complex problems, to constantly learn and
              challenge myself, and to make great things happen with great
              people. <br />
              <br />
              Hailing originally from Calgary, I first moved to Toronto, then
              Boston, to pursue Bachelor's and Master's Degrees in Music
              Performance. After sustaining an injury to my wrist, I then
              pivoted to arts management, and then the non-profit world at
              large, where I worked for the last decade. In 2022, I decided it
              was time to make a change, and took a chance on a program at
              Inception U, becoming a Full-Stack Developer.
              <br /> <br />
              I'm so thrilled to be in my dream career, and can't wait to join
              your team!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
