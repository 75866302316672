import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import "../App.css";
import useIsMounted from "../utils/useIsMounted";
import { AnimateKeyframes } from "react-simple-animate";

function NavMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const isMounted = useIsMounted();

  function handleClick() {
    // console.log("state was", isOpen);
    // console.log("state will be", !isOpen);
    setIsOpen(!isOpen);
  }
  function Submenu() {
    return (
      <div className={`submenu ${isMounted ? "mounted" : ""}`}>
        {/* <div className="submenu"> */}
        <button
          onClick={() => {
            handleClick();
          }}
        >
          <a href="#home">Home</a>
        </button>
        <button
          onClick={() => {
            handleClick();
          }}
        >
          <a href="#about">About</a>
        </button>
        <button
          onClick={() => {
            handleClick();
          }}
        >
          <a href="#projects">
            Projects
          </a>
        </button>
        <button
          onClick={() => {
            handleClick();
          }}
        >
          <a href="#contact">Contact</a>
        </button>
        <button
          id="specialbutton"
          onClick={() => {
            handleClick();
          }}
        >
          <a
            href="https://drive.google.com/file/d/19_fxonZcqRjR1m8s8Bugmr5QyHmnaSEW/view?usp=sharing"
            target="blank"
            rel="norel noreferrer"
          >
            Resumé
          </a>
        </button>
      </div>
    );
  }

  // if (isOpen) {
  //   return <Submenu />;
  // } else {
  return (
    <div className="mobilebar">
      {isOpen ? (
        <MenuOpenIcon
          fontSize="inherit"
          className="mobile-icon"
          // color="black"
          onClick={() => {
            handleClick();
          }}
        />
      ) : (
        <MenuIcon
          fontSize="inherit"
          className="mobile-icon"
          onClick={() => {
            handleClick();
          }}
        />
      )}
      {/* <Submenu /> */}
      {isOpen ? <Submenu /> : null}
    </div>
  );
}
// }

export default NavMenu;
