import * as React from 'react';
import '../App.css';

export default function MenuBar() {
  return (
    <div className="menubar">
      <div>
        <button>
          <a href="#home">Home</a>
        </button>
        <button>
          <a href="#about">About</a>
        </button>
        <button>
          <a href="#projects">
            Projects
          </a>
        </button>
        <button>
          <a href="#contact">Contact</a>
        </button>
        <button id="specialbutton">
          <a
            href="https://drive.google.com/file/d/19_fxonZcqRjR1m8s8Bugmr5QyHmnaSEW/view?usp=sharing"
            target="blank"
            rel="norel noreferrer"
          >
            Resumé
          </a>
        </button>
      </div>{' '}
    </div>
  );
}
