import '../App.css';
import project3 from '../media/egghunter.jpg';
import project1 from '../media/Got-Bucked-Headshot.jpg';
import project2 from '../media/Law-and-Disorder.png';

function Projects() {
  return (
    <section id="projects">
      <div>
        <h2 className="sectionheader">Projects</h2>
        {/* <div className="project-table"> */}
        <p>More information on all my projects can be found on my Linkedin</p>
        <div className="project-info-div">
          <div className="project-container">
              <a
                className="web-link"
                href="http://www.egghunter.site"
                target="_blank"
                rel="noopener noreferrer"
              >
            <div className="image-div">
              <img src={project3} alt="EggHunter" />
            </div>{' '}
            <div className="project-text">
              <h4>
                Inception U Project 3: <br />
                EggHunter
              </h4>
                <p>www.egghunter.site
                </p>
            </div>
              </a>
          </div>
          <div className="project-container">
              <a
                className="web-link"
                href="https://law-and-disorder-production.up.railway.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
            <div className="image-div">
              <img src={project2} alt="Law and Disorder" />
            </div>
            <div className="project-text">
              <h4>
                Inception U Project 2:
                <br /> Law and Disorder
              </h4>
               <p>https://law-and-disorder-production.up.railway.app/</p>
            </div>
              </a>
          </div>
          <div className="project-container">
              <a
                className="web-link"
                href="https://github.com/maggiechew/GetBuckedSon"
                target="_blank"
                rel="noopener noreferrer"
              >
            <div className="image-div">
              <img src={project1} alt="Get Bucked" />
            </div>
            <div className="project-text">
              <h4>
                Inception U Project 1:
                <br /> Get Bucked
              </h4>
                <p>https://github.com/maggiechew/GetBuckedSon</p>
            </div>
              </a>
          </div>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
}

export default Projects;
