import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useEffect, useState } from "react";
import { AnimateKeyframes } from "react-simple-animate";
import "./App.css";
import { AboutMe, Mobile } from "./components/AboutMe";
import InTouch from "./components/InTouch";
import MenuBar from "./components/MenuBar";
import NavMenu from "./components/NavMenu";
import Projects from "./components/Projects";

function App() {
  const [showMobile, setShowMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 900) {
      setShowMobile(false);
    } else setShowMobile(true);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 900) {
        setShowMobile(false);
      } else setShowMobile(true);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App">
      <header>{showMobile ? <NavMenu /> : <MenuBar />}</header>
      <body className="Body">
        <section id="home">
          <div className="enter-div">
            <h1> Hello World </h1>
            <div className="subtitle">
              <ArrowOutwardIcon fontSize="inherit" className="icon" />
              <div>
                <h2>Maggie Chew</h2>
                <h3>Full Stack Web Development</h3>
              </div>
            </div>
          </div>
        </section>
        {showMobile ? <Mobile /> : <AboutMe />}
        <Projects />
        <InTouch />
      </body>
    </div>
  );
}

export default App;
