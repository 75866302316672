import React from 'react';
import '../App.css';

function InTouch() {
  return (
    <section id="contact">
      <h2 className="sectionheader">Get In Touch</h2>
      <p>I would love to hear from you!</p>
      <div className="get-in-touch">
        <div className="links">
          <a
            className="App-link"
            href="https://www.linkedin.com/in/maggie-chew-00226273"
            target="_blank"
            rel="noopener noreferrer"
          >
            Linkedin{' '}
          </a>
          <br />
          <a
            className="App-link"
            href="https://github.com/maggiechew"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github{' '}
          </a>
          <br />
          <a
            className="App-link"
            href="https://docs.google.com/document/d/e/2PACX-1vRYZtF8Nx3NinboP4cXY7MCW9jf1Lic7eze1Y1AD-SUJ6rG6p46k5Fp90j7ngH6fQ/pub"
            target="_blank"
            rel="noopener noreferrer"
          >
            Resumé{' '}
          </a>
        </div>

        <div className="contact-form">
          <div className="contact-body">
            <h4>Let me know what you're thinking about...</h4>
            <form action="/contact.php" method="POST">
              <table className="form-table">
                <tbody>
                  <tr>
                    <td>
                      <label for="name">Enter your name: </label>
                    </td>
                    <td>
                      <input type="text" name="name" id="name" required />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="email">Enter your email: </label>
                    </td>
                    <td>
                      <input
                        type="email"
                        name="email_address"
                        id="email"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="message" id="message">
                        Message:{' '}
                      </label>
                    </td>
                    <td>
                      <input type="text" name="message" id="message" required />
                    </td>
                  </tr>
                </tbody>
              </table>
              <input type="submit" id="submit" value="Send" />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InTouch;
